// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$avto100_3-0-theme-primary: mat.define-palette(mat.$indigo-palette);
$avto100_3-0-theme-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$avto100_3-0-theme-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$avto100_3-0-theme: mat.define-light-theme((
  color: (
    primary: $avto100_3-0-theme-primary,
    accent: $avto100_3-0-theme-accent,
    warn: $avto100_3-0-theme-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($avto100_3-0-theme);

/* You can add global styles to this file, and also import other style files */



$font-stack: Rubik;
$primary-color: #3E5D94;
$primary-color2: #FEC8C8;
$success-color: #2BAA16;
$accent-color: #fd8fa9;
$text-white: #FAF5F2;
$text-dark: #181818;
$text-error: #B00020;
$text-other: #DADADA;
$typography-gray-color: #808080;
$typography-gray4-color: rgba(0, 0, 0, 0.25);

$btn-primary-color: #FD8FA9;
$btn-primary-color-click: #F74F76;
$btn-primary-color-disabled: #FEC7D4;


$btn-danger-text-color: #fff;


$warning-color: #FFCC80;
$success-card-color: #C8E6C9;

$typography-gray2-color: rgba(0, 0, 0, 0.75);
$typography-gray5-color: #D9D9D9;

$main-white-color: #ffffff;
$main-primary-color: #3E5D94;
$main-primary-color-dark: #253757;
$main-primary-light-color: #B2BED4;
$main-primary-color-opacity-20: rgba(62, 93, 148, 0.2);
$elevation-1: 0 4px 8px rgba(8, 9, 10, 0.25), 0 2px 4px rgba(8, 9, 10, 0.25);
$main-primary-color-dark: #155BB1;

$success-color-disabled: #E8F5E9;
$btn-success-text-color: #fff;


body {
  font: 100% $font-stack !important;
  background-image: url("/assets/siteImg/background.png");
  color: $main-primary-color !important;
  margin: 0;
}

body::-webkit-scrollbar {
  width: 15px; /* ширина всей полосы прокрутки */
}

body::-webkit-scrollbar-track {
  background: $typography-gray5-color; /* цвет зоны отслеживания */
}

body::-webkit-scrollbar-thumb {
  background-color: #3f51b5; /* цвет бегунка */
  border-radius: 20px; /* округлось бегунка */
  border: 5px solid $typography-gray5-color; /* отступ вокруг бегунка */
}


.hover:hover {
  box-shadow: $elevation-1 !important;

}

.font {
  font: 100% $font-stack !important;
  font-size: 24px !important;
  font-weight: 500 !important;
}

h1, h2, h3, h4 {
  font-family: inherit !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  margin-bottom: .5rem !important;
  color: $main-primary-color !important;

}

a {
  text-decoration: none;
  color: inherit;
}

a:hover {
  text-decoration: underline;
}

ul, ol, p {
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.7;
  color: $main-primary-color;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1300px
  }
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto
}

@media (min-width: 576px) {
  .container {
    max-width: 540px
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1300px
  }
}


.mr-2 {
  margin-right: 1rem;
}

.mt-2 {
  margin-top: 1rem;
}
.mt-3 {
  margin-top: 1.5rem;
}

.mt-4 {
  margin-top: 2rem;
}

.mb-2 {
  margin-bottom: 1rem;
}

.mb-1 {
  margin-bottom: .5rem;
}

.my-2 {
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.mx-2 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mr-1 {
  margin-right: .5rem;
}

.w-100 {
  width: 100%;
}

.text-white {
  color: $text-white !important;
}

.btn-block {
  display: block !important;
  width: 100% !important;
}

.btn-primary {
  color: $text-white !important;
  background-color: $primary-color !important;
}

.btn-primary:hover {
  color: $text-white !important;
  background-color: $primary-color !important;
  transform: translateY(-1px);
  box-shadow: 0 7px 14px rgba(50, 50, 93, .1), 0 3px 6px rgba(0, 0, 0, .08);
}


.btn-success {
  color: $text-white !important;
  background-color: $success-color !important;
}

.btn-success:hover {
  color: $text-white !important;
  background-color: $success-color !important;
  transform: translateY(-1px);
  box-shadow: 0 7px 14px rgba(50, 50, 93, .1), 0 3px 6px rgba(0, 0, 0, .08);
}

.btn{
  border-radius: 8px!important;
}

.btn.active {
  color: $text-white !important;
  background-color: $primary-color !important;
}


.btn-alert {
  color: $text-white !important;
  background-color: $btn-primary-color !important;
}

.btn-alert:hover {
  color: $text-white !important;
  background-color: $btn-primary-color-click !important;
  transform: translateY(-1px);
  box-shadow: 0 7px 14px rgba(50, 50, 93, .1), 0 3px 6px rgba(0, 0, 0, .08);
}

.btn-alert-outline {
    background-color: $text-white!important;
    color: $accent-color!important;
    border: 1px solid $accent-color !important;
  &:hover {
    color: $text-white !important;
    background-color: $btn-primary-color-click !important;
    transform: translateY(-1px);
    box-shadow: 0 7px 14px rgba(50, 50, 93, .1), 0 3px 6px rgba(0, 0, 0, .08);
  }
}

.btn-round {
  border-radius: 20px !important;
}

.btn-lg {
  padding: 1rem !important;
  font-size: 1.5rem !important;
  line-height: 48px !important;
  border-radius: 0.3rem;
  height: 5rem!important;
}

.btn-md {
  padding: 0 !important;
  font-size: 1rem !important;
  line-height: 36px !important;
  border-radius: 0.3rem;
  height: 3rem!important;
}

.btn-sm {
  padding: 6px !important;
  border-radius: 5px !important;
  font-size: 10px !important;
  line-height: 16px !important;
  max-height: 30px !important;
}

.btn-primary-outline {
  color: $typography-gray2-color !important;
  background: $main-primary-light-color !important;

}



.btn-primary-border {
  border: 1px solid $primary-color !important;
}

.header-info-block {
  margin-bottom: 1rem;

  .language-alert-text {
    background: $primary-color2;
    margin: 0 auto;
    text-align: center;
    display: flex;
    justify-content: center;
    gap: 2rem;
    align-items: center;
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  .company-info-block {
    display: flex;
    justify-content: space-around;
    gap: 2rem;
    align-items: center;
  }

  .localization-select-width-block {
    width: 4rem;
  }
}


.wrap {
  background-color: rgba(255, 255, 255, 0.8);
  margin-bottom: -10px;
  box-sizing: inherit;
}

.header {
  position: sticky;
  top: 0;
  z-index: 20;
}

.header-shadow {
  box-shadow: 0 10px 15px #08090a40;
  background: #ffffff;
}

.phone-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;

  .h3 {
    font-size: 36px;
    color: $main-primary-color !important
  }
}

.phone-number-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  .btn-show-more {
    border-radius: 50%;
    display: inline-block;
    width: 24px;
    height: 24px;
    background: $main-primary-color;
  }
}

.link {
  color: $main-primary-color !important;
  text-decoration: none !important;;
}

.link-alert {
  color: $btn-primary-color !important;
  text-decoration: none !important;;
}

.link:hover {
  color: $main-primary-color-dark !important;
  text-decoration: underline !important;
  font-weight: bold !important;;
}

.link-alert:hover {
  color: $btn-primary-color !important;
  text-decoration: underline !important;
  font-weight: bold !important;;
}

//text

.h0 {
  font-family: $font-stack;
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 60px;
  color: $primary-color;
}

.h1 {
  font-family: $font-stack;
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 48px;
  color: $primary-color;
}

.h2 {
  font-family: $font-stack;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 36px;
  color: $primary-color;
}

.h3 {
  font-family: $font-stack;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  color: $primary-color;
}

.h4 {
  font-family: $font-stack !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: $primary-color !important;
}

.h5 {
  font-family: $font-stack;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: $primary-color;
}

.h6 {
  font-family: $font-stack !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 12px !important;
  line-height: 18px !important;
  color: $primary-color !important;
}

.h7 {
  font-family: $font-stack;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 18px;
  color: $primary-color;
}

.tooltip {
  background-color: $text-dark !important;
  border-radius: 5px !important;
  color: $text-white !important;
  font-size: 0.7rem !important;
}

.cursor-pointer {
  cursor: pointer;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.text-gray {
  color: $typography-gray-color;
}

.text-bold {
  font-weight: bold !important;
}

.text-success {
  color: $success-color !important;
}

.text-danger {
  color: $btn-primary-color-click !important;
}

.mat-mdc-tooltip .mdc-tooltip__surface {
  --mdc-plain-tooltip-container-color: $text-dark !important; /* Новый цвет фона */
  --mdc-plain-tooltip-supporting-text-color: $text-white !important; /* Новый цвет текста */
  --mdc-plain-tooltip-supporting-text-font: $font-stack !important;
}

.dialog-title-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.empty-cart-block {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem
}


.snack-bar-danger.mat-mdc-snack-bar-container {
  .mdc-snackbar__surface {
    background: $text-error;

    .mat-mdc-button {
      color: $text-white !important;
    }
  }

  .mat-mdc-snack-bar-label.mdc-snackbar__label {
    color: $text-white;
    font-weight: 500;
    white-space: pre-wrap
  }
}

.snack-bar-success.mat-mdc-snack-bar-container {
  .mdc-snackbar__surface {
    background: $success-color;

    .mat-mdc-button {
      color: $text-white !important;
    }
  }

  .mat-mdc-snack-bar-label.mdc-snackbar__label {
    color: $text-white;
    font-weight: 500;
    white-space: pre-wrap
  }
}


.snack-bar-success-action.mat-mdc-snack-bar-container {
  .mdc-snackbar__surface {
    background: $success-color;

    .mat-mdc-button {
      color: $text-white !important;
    }
  }

  .mat-mdc-snack-bar-label.mdc-snackbar__label {
    color: $text-white;
    font-weight: 500;
    white-space: pre-wrap
  }
}


.our-advantage {
  background-color: $primary-color;
  color: $text-white !important;
  min-height: 139px;
}

.product-group-item-block {
  border: 1px solid $primary-color;
  padding: 0.5rem;
  margin: 1rem 0
}

@media (max-width: 900px) {
  .header-info-block {
    display: none;
  }
}

.alert-message {
  font-weight: bold;
  background: rgb(236, 250, 0);
  padding-bottom: 0 !important;
  background: linear-gradient(0deg, rgba(236, 250, 0, 1) 50%, rgba(0, 212, 255, 1) 50%);
  color: #ff0000 !important;
}


.success-dot{
  display: inline-block;
  width:10px;
  height:10px;
  background: $success-color !important;
  content: '';
  border-radius: 50%;
  margin-right: 8px;
}

.alert-dot{
  display: inline-block;
  width:10px;
  height:10px;
  background: $warning-color !important;
  content: '';
  border-radius: 50%;
  margin-right: 8px;
}

.danger-dot{
  display: inline-block;
  width:10px;
  height:10px;
  background: $text-error !important;
  content: '';
  border-radius: 50%;
  margin-right: 8px;
}

.my-3{
  margin-top:1.5rem;
  margin-bottom:1.5rem;
}

::ng-deep{
  .cdk-overlay-dark-backdrop {
    background: rgba(189, 189, 189, 0.6) !important;
    backdrop-filter: blur(4px) !important;
  }
}
